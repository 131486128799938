<template>
  <div>
    <b-sidebar
        id="sidebar-add-new-event"
        sidebar-class="sidebar-lg"
        :visible="isEventHandlerSidebarActive"
        bg-variant="white"
        shadow
        backdrop
        no-header
        right
        @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <b-overlay
            :show="show"
            no-wrap
        />
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ eventLocal.id ? 'Editar' : 'Agregar' }} Cita <span v-if="eventLocal.id">{{ partner.name }}</span>
          </h5>
          <div>
            <feather-icon
                v-if="eventLocal.id"
                icon="TrashIcon"
                class="cursor-pointer"
                @click="$emit('remove-event'); hide();"
            />
            <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
            />
          </div>
        </div>
        <b-form
            @reset.prevent="resetForm"
            v-if="!eventLocal.id"
            class="p-2"
            @submit.prevent="createAppointment('create')"

        >
          <b-form-group
              label="Tipo de cita"
          >
            <b-form-select
                v-model="appointment.source"
                @change="verifySource()"
                :options="sources"
            />
          </b-form-group>

          <b-form-group
              label="Pacientes"
              label-for="h-email"
          >
            <vue-autosuggest
                :suggestions="patients"
                :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'Seleccionar'}"
                @input="getPatientSelect"
                @selected="patientVerify"
            >
              <template slot-scope="{suggestion}">
                <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
              </template>
            </vue-autosuggest>
          </b-form-group>
          <b-form-group
              label="Cita con"
              label-for="h-email"
          >
            <v-select
                @input="groupsVerify()"
                v-model="select_group"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="value"
                :options="groups"
            />
          </b-form-group>

          <b-form-group
              :label="place_active ? 'Salas' : 'Medicos'"
              label-for="h-email"
          >
            <v-select
                @input="medicalVerify()"
                v-model="select_medical"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="value"
                :options="medicals"
            />
          </b-form-group>
          <b-form-group
              label="Hora de inicio"
              label-for="start-date"
          >
            <flat-pickr
                @on-change="verifyDate('start')"
                v-model="appointment.app_dt_start"
                class="form-control"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:m',locale: {

        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        },
        months: {
          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
          longhand: ['Enero', 'Febrero', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        }}}"
            />

          </b-form-group>

          <b-form-group
              label="Hora final"
              label-for="end-date"
          >
            <flat-pickr
                @on-change="verifyDate('end')"
                v-model="appointment.app_dt_stop"
                class="form-control"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:m',locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        },
        months: {
          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
          longhand: ['Enero', 'Febrero', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        }}}"
            />

          </b-form-group>

          <b-form-group
              label="Tipo de Paciente"
              label-for="h-email"
          >
            <v-select
                @input="pricelistVerify()"
                v-model="select_price_list"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="value"
                :options="pricelists"
            />
          </b-form-group>
          <b-form-group
              v-if="verified_treatment"
              label="Subir Archivo"
              label-for="h-email"
          >
            <b-form-file

                @change="handleImage()"
                id="file"
                placeholder="Subir archivo."
                drop-placeholder="Drop file here..."
            />
          </b-form-group>


          <label for="textarea-default">Notas</label>
          <b-form-textarea
              v-model="appointment.description"
              id="textarea-default"
              placeholder="Notas"
              rows="3"
          />
          <b-button class="mt-1"
                    v-if="available_appointent"
                    v-ripple.400="'rgba(25, 17, 46, 0.15)'"
                    type="submit"
                    variant="outline-secondary"
          >
            Guardar Cita
          </b-button>

        </b-form>
        <div v-else>
          <b-form

              class="p-2"
              @submit.prevent="createAppointment('edit')"

          >
            <b-form-group
                label="Tipo de cita"
            >
              <b-form-select
                  v-model="appointment.source"
                  :options="sources"
              />
            </b-form-group>

            <b-form-group
                label="Cita con"
                label-for="h-email"
            >
              <v-select
                  @input="groupsVerify()"
                  v-model="select_group"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="value"
                  :options="groups"
              />
            </b-form-group>

            <b-form-group
                :label="place_active ? 'Salas' : 'Medicos'"
                label-for="h-email"
            >
              <v-select
                  @input="medicalVerify()"
                  v-model="select_medical"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="value"
                  :options="medicals"
              />
            </b-form-group>

            <b-form-group
                label="Hora de inicio"
                label-for="start-date"
            >
              <flat-pickr
                  @on-close="verifyDate('start')"
                  v-model="appointment.app_dt_start"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:m',locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        },
        months: {
          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
          longhand: ['Enero', 'Febrero', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        }}}"
              />

            </b-form-group>

            <b-form-group
                label="Hora final"
                label-for="end-date"
            >
              <flat-pickr
                  @on-close="verifyDate('end')"
                  v-model="appointment.app_dt_stop"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:m',locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        },
        months: {
          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
          longhand: ['Enero', 'Febrero', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        }}}"
              />

            </b-form-group>
            <b-form-group
                label="Tipo de Paciente"
                label-for="h-email"
            >
              <v-select
                  @input="pricelistVerify()"
                  v-model="select_price_list"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="value"
                  :options="pricelists"
              />
            </b-form-group>


            <b-form-group
                label="Proceso Clinica"
            >
              <b-form-select
                  v-model="appointment.appoint_state_clinical"
                  :options="clinical_states"
              />
            </b-form-group>
            <div v-if="!verified_treatment_edit">

              <b-form-group
                  v-if="new_file"
                  label="Subir Archivo"
                  label-for="h-email"
              >
                <b-form-file

                    @change="handleImage()"
                    id="file"
                    placeholder="Subir archivo."
                    drop-placeholder="Drop file here..."
                />
              </b-form-group>
            </div>
            <label for="textarea-default">Notas</label>
            <b-form-textarea
                v-model="appointment.description"
                id="textarea-default1"
                placeholder="Notas"
                rows="3"
            />
            <b-button
                class="mt-1"
                v-if="available_appointent"
                v-ripple.400="'rgba(25, 17, 46, 0.15)'"
                type="submit"
                variant="outline-secondary"
            >
              Guardar Cambios
            </b-button>
            <br>
            <div v-if="verified_treatment">
              <b-row>

                <b-col cols="4">
                  <a @click="downloads(eventLocal.id, appointment.treatment_file, appointment.appoint_date,partner.name)"
                     style="text-align: center">
                    <feather-icon icon="FolderIcon" size="100"/>
                    <br>Descargar Archivo </a>
                </b-col>
                <b-col cols="4">
                </b-col>
                <b-col cols="4">
                  <a @click="deleteFileAppointment(eventLocal.id)" style="text-align: right">
                    <feather-icon icon="TrashIcon" style="color: darkred" size="20"/>
                  </a>
                </b-col>
              </b-row>

            </div>

          </b-form>
        </div>

        <!-- Body -->

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BAvatar,
  BFormTextarea,
  BButton,
  BFormInvalidFeedback,
  BFormSelect,
  BOverlay, BFormFile
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email, url} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import {ref, toRefs} from '@vue/composition-api'
import useCalendarEventHandler from './useCalendarEventHandler'
import useCalendar from '../useCalendar'
import {VueAutosuggest} from 'vue-autosuggest'
import {mapActions, mapState} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BAvatar,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver, VueAutosuggest, BFormSelect, BOverlay, BFormFile
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    refreshView: Function,
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  watch: {
    async eventLocal() {
      if (this.eventLocal.id) {
        this.appointment.customer = 0

        this.sources = []
        this.patients = []
        this.appointment = {}
        this.verified_treatment = false
        this.verified_treatment_edit = false
        this.new_file = false
        this.appointment.customer = ''
        this.select_medical = {id: '', value: ''}
        this.select_group = {id: '', value: ''}
        this.select_price_list = {id: '', value: ''}
        await this.verifyAppointment(this.eventLocal.id)
      }

    },
    async isEventHandlerSidebarActive() {

      this.appointment.customer = 0
      this.sources = []
      this.appointment = {}
      this.patients = []
      this.appointment.customer = 0
      this.verified_treatment = false
      this.verified_treatment_edit = false
      this.new_file = false
      this.select_medical = {id: '', value: ''}
      this.select_group = {id: '', value: ''}
      this.select_price_list = {id: '', value: ''}
      this.sidebar_status = true
      await this.getPriceListSelect()
      await this.getMedicalSelect()
      await this.getGroupSelect()
      await this.getSource()

    }
  },
  data() {
    return {
      verified_treatment: false,
      verified_treatment_edit: false,
      new_file: false,
      select_medical: {id: '', value: ''},
      select_group: {id: '', value: ''},
      select_price_list: {id: '', value: ''},
      sources: [{value: '', text: 'Seleccione una opción'},
      ],
      patients: [],
      sidebar_status: false,
      show: false,
      groups: [],
      pricelists: [],
      medicals: [],
      available_appointent: true,
      place_active: false,
      clinical_states: [
        {value: 'p', text: 'Programa'},
        {value: 'r1', text: 'Revisión coordinador médico'},
        {value: 'r2', text: 'Revisión enfermería'},
        {value: 'rc', text: 'Revisión Conjunta'},
        {value: 'e', text: 'Envío de aseguradora/ cotizaciones'},
        {value: 'f', text: 'Farmacia'},
      ],
      partner: {},
      appointment: {
        id: null,
        customer: '',
        appoint_person_id: '',
        description: '',
        app_dt_start: '',
        app_dt_stop: '',
        appoint_date: '',
        appoint_state: 'new',
        pricelist_id: '',
        currency_id: 33,
        appoint_state_clinical: "p",
        appoint_group_id: '',
        time_slot: '',
        time_slot_day: '',
        source: '',
        treatment_file: null
      },
      required,
      email,
      url,
    }
  },
  methods: {
    ...mapActions('patient', ['findPatientsSearch']),
    ...mapActions('calendarStoreModule', ['fetchEvents']),
    ...mapActions('appointment', ['findAppointmentDoctor', 'findAppointmentPriceList', 'findAppointmentGroups', 'appointmentAvailable',
      'findSlotVerify', 'createAppointmentSend', 'findAppointmentSource', 'findAppointmentUserOne', 'findApointeeSearch', 'downloadFileAppointment', 'deleteFile']),
    async handleImage() {
      const file = document.querySelector(`#file`).files[0]
      const file_convert = await this.getBase64(file)
      file_convert.onload = (event) => {
        this.appointment.treatment_file = event.target.result
        /* this.company.file_key = event.target.result.split(',')[1]*/

      };
    },
    async deleteFileAppointment(id) {
      const response = await this.deleteFile(id)
      this.show = true
      if (response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Se borro el archivo`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.show = false
        this.new_file = true
        this.verified_treatment = false
      } else {

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al borrar el archivo',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      }
      this.show = false
    },
    async downloads(id, file, name, partner) {
      const [, type] = file.split(';')[0].split('/');
      const send = {
        id,
        type,
        name,
        partner
      }
      await this.downloadFileAppointment(send)
    },
    async getBase64(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return reader
    },
    async verifySource() {
      let name_source;
      for (const a of this.sources) {
        if (a.value === this.appointment.source) {
          name_source = a.text
        }
      }
      if (name_source === 'Tratamiento') {
        let new_group = []
        const response = await this.findAppointmentGroups()
        for (const a of response) {
          if (a.is_place) {
            this.select_group = {id: a.id, value: a.group_name}
            new_group.push({
              id: a.id,
              value: a.group_name,
              verifed: a.is_place
            })
          }
        }
        this.groups = []
        this.groups = new_group
        await this.searchGroup(this.select_group.id)
        this.appointment.appoint_group_id = this.select_group.id
        this.place_active = true
        this.verified_treatment = true

      } else {
        let new_group = []
        this.medicals = []
        this.appointment.treatment_file = null
        this.select_group = {id: '', value: ''}
        const response = await this.findAppointmentGroups()
        for (const a of response) {
          if (!a.is_place) {
            new_group.push({
              id: a.id,
              value: a.group_name,
              verifed: a.is_place
            })
          }

        }
        this.verified_treatment = false
        this.groups = []
        this.groups = new_group
        this.place_active = false
      }
    },
    async searchGroup(id) {
      const response = await this.findApointeeSearch(id);
      this.medicals = []
      for (const a of response) {
        this.medicals.push({
          id: a.ResPartnerAppointment.id,
          value: a.ResPartnerAppointment.name,
        })
      }

    },
    async verifyAppointment(id) {
      const response = await this.findAppointmentUserOne(id);

      const dat1 = this.resHoursOdoo(response.app_dt_start)
      const dat2 = this.resHoursOdoo(response.app_dt_stop)
      this.appointment = response

      this.appointment.app_dt_start = dat1
      this.appointment.app_dt_stop = dat2
      this.partner = response.ResPartner;
      this.select_medical.id = response.ResPartnerAppointment.id
      this.select_medical.value = response.ResPartnerAppointment.name
      this.select_price_list.id = response.ProductPricelist.id
      this.select_price_list.value = response.ProductPricelist.name
      this.select_group.id = response.AppointmentGroup.id
      this.select_group.value = response.AppointmentGroup.group_name
      if (response.AppointmentGroup.is_place) {
        if (response.treatment_file) {
          this.verified_treatment = true
        } else {
          this.new_file = true
          this.verified_treatment = false
        }
      }


      delete this.appointment.ResPartnerAppointment
      delete this.appointment.ResPartner
      delete this.appointment.ProductPricelist
      delete this.appointment.AppointmentGroup
      delete this.appointment.AppointmentSource
    },
    async verifyDate(type) {
      if (this.appointment.app_dt_start) {
        const separate = this.appointment.app_dt_start.split(' ')
        this.appointment.appoint_date = separate[0]
        this.appointment.time_slot_day = this.dateString(separate[0])
        this.appointment.time_slot = await this.getSlot(this.appointment.time_slot_day)
        if (this.appointment.app_dt_start && this.appointment.app_dt_stop && this.appointment.appoint_person_id) {
          console.log(this.appointment.app_dt_start)
          console.log(this.appointment.app_dt_stop)

          await this.medicalVerify()
        }
      }

    },
    async getSlot(day) {
      if (day) {
        const response = await this.findSlotVerify(day.toLowerCase());
        if (response) {
          this.available_appointent = true
          return response
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Este dia no esta disponible',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          this.available_appointent = false
          return ''
        }
      }


    },
    async createAppointment(appointment) {

      let res;
      let res_error;
      if (appointment === 'create') {
        res = 'creada'
        res_error = 'crear'
      } else {
        res = 'editada'
        res_error = 'editar'
      }
      this.show = true
      const response = await this.createAppointmentSend(this.appointment)
      if (response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Cita ${res}`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.show = false
        this.refreshView()
        this.appointment = {}
        this.partner = {}
        this.$emit('update:is-event-handler-sidebar-active', false)

      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Error al ${res_error} cita`,
            icon: 'error',
            variant: 'warning',
          },
        });
        this.show = false

      }
    },
    async patientVerify(item) {
      this.appointment.customer = item.item.id
    },
    async getPatientSelect(text) {

      if (text === '' || text === undefined) {
        this.patients = []
        return
      }
      this.patients = []
      let cou = []
      const response = await this.findPatientsSearch(text);
      for (const a of response) {
        cou.push({
          id: a.id,
          name: a.name,
        })
      }
      this.patients = [{
        data: cou,
      }]

    },
    async medicalVerify() {
      this.appointment.appoint_person_id = this.select_medical.id;
      if (this.appointment.app_dt_start && this.appointment.app_dt_stop) {

        const send = {
          start: this.sumHoursOdoo(this.appointment.app_dt_start),
          end: this.sumHoursOdoo(this.appointment.app_dt_stop),
          medical_id: this.select_medical.id
        }
        const response = await this.appointmentAvailable(send);

        if (!response) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `No hay citas en esta Fecha`,
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          this.available_appointent = response
        } else {
          this.available_appointent = response
        }
      }
    },
    async pricelistVerify() {
      this.appointment.pricelist_id = this.select_price_list.id;
    },
    async groupsVerify() {
      this.appointment.appoint_group_id = this.select_group.id;
      await this.searchGroup(this.select_group.id)
    },
    async getGroupSelect() {
      this.groups = []
      const response = await this.findAppointmentGroups()
      for (const a of response) {
        this.groups.push({
          id: a.id,
          value: a.group_name,
          verifed: a.is_place
        })
      }
    },
    async getMedicalSelect() {
      this.medicals = []
      const response = await this.findAppointmentDoctor()
      for (const a of response) {
        this.medicals.push({
          id: a.id,
          value: a.name,
        })
      }
    },
    async getSource() {
      const response = await this.findAppointmentSource()
      for (const a of response) {
        this.sources.push({
          value: a.id,
          text: a.name,
        })
      }
    },
    async getPriceListSelect() {

      this.pricelists = []
      const response = await this.findAppointmentPriceList()
      for (const a of response) {
        this.pricelists.push({
          id: a.id,
          value: a.name,
        })
      }
    }
  },


  setup(props, {emit}) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,

      // UI
      onSubmit,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)


    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    function refreshCalendar() {
      const {
        fetchEvents,
      } = useCalendar()
      fetchEvents();
    }

    clearFormData.value = clearForm
    return {
      refreshCalendar,
      // Add New Event
      eventLocal,
      calendarOptions,
      onSubmit,
      guestsOptions,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    }
  },
  computed: {
    ...mapState('calendarStoreModule', ['selectedCalendars'])
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
